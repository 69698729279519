import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';

  const loadUserDetails = useCallback(async (token) => {
    try {
      // Include the token in the request header
      const response = await axios.get(`${apiUrl}/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const userData = response.data;
      setUser(userData); // Set full user data
    } catch (error) {
      console.error('Error loading user details:', error);
      logout();
    }
  }, [apiUrl]);

  const login = useCallback(async (token) => {
    setLoading(true);
    localStorage.setItem('token', token);
    await loadUserDetails(token);
    setLoading(false);
  }, [loadUserDetails]);

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  const updateUser = (updatedUserData) => {
    setUser((currentUser) => ({ ...currentUser, ...updatedUserData }));
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      login(token).catch(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [login]);

  const refreshToken = async () => {
    try {
      const response = await axios.post(`${apiUrl}/refresh-token`);
      const newToken = response.data.token;
      localStorage.setItem('token', newToken);
      await loadUserDetails(newToken);
    } catch (error) {
      console.error('Error refreshing token:', error);
      logout();
    }
  };
  
  // Add refreshToken to the value provided by AuthContext.Provider
  return (
    <AuthContext.Provider value={{ user, setUser, login, logout, isLoggedIn: !!user, loading, updateUser, refreshToken }}>
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => useContext(AuthContext);
