import React from 'react';

const PrintableComponent = React.forwardRef(({ issuedItems, IssuedUserName, LoggedInUserName, LoggedInUserId, IssuedUserId, IssuedUserRank, LoggedInUserRank }, ref) => {
// Function to capitalize the first letter
const capitalizeFirstLetter = (string) => {
    if (string && typeof string === 'string') {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return '';
};

    // Capitalize the first letter of IssuedUserName and LoggedInUserName
    const formattedIssuedUserName = capitalizeFirstLetter(IssuedUserName);
    const formattedLoggedInUserName = capitalizeFirstLetter(LoggedInUserName);

    const containerStyle = {
        margin: '10px 20px', // Reduced top and bottom margins
        fontFamily: 'Arial, sans-serif',
        color: '#333',
        lineHeight: '1.4',
        fontSize: '12px',
    };

    const bannerStyle = {
        width: '100%', // Full width of the container
        display: 'block', // Ensure it's a block-level element
        margin: '0 auto', // Center the image and remove default margin
    };

    const contentStyle = {
        paddingLeft: '30px',    
        paddingRight: '30px',
    };

    const listItemStyle = {
        paddingBottom: '1px',
        marginBottom: '1px',
    };

    const itemDetailStyleInline = {
        display: 'inline',
        fontWeight: 'normal',
        marginRight: '10px', // Add some spacing between item details
        fontSize: '11px', // Smaller font size for item details
    };

    const signatureStyle = {
        marginTop: '10px', // Reduced margin
        borderTop: '1px solid #ddd',
        paddingTop: '30px', // Reduced padding
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    };

    const abbreviateRank = (rank) => {
        const rankAbbreviations = {
            'Officer': 'Ofc.',
            'Detective': 'Det.',
            'Private First Class': 'PFC.',
            'Corporal': 'Cpl.',
            'Sergeant': 'Sgt.',
            'Captain': 'Capt.',
            'Chief': 'Chief',
            'Civilian': 'Civ.',
        };
        return rankAbbreviations[rank] || rank;
    };
    
    
return (
    <div ref={ref} style={containerStyle}>
    <img src="/images/banner.png" alt="Banner" style={bannerStyle} />
    <div style={contentStyle}>
        <p><strong>From:</strong> {abbreviateRank(LoggedInUserRank) || 'No Rank'} {formattedLoggedInUserName} #{LoggedInUserId}</p>
        <p><strong>To:</strong> {abbreviateRank(IssuedUserRank)} {formattedIssuedUserName} #{IssuedUserId}</p>
        <p>
            <strong>Date:</strong> {new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
        </p>
        <p>
            <strong>Subject:</strong> Issuance of Equipment
        </p>
        <br />
        <p>The following equipment has been issued to you:</p>
        <br />
        <ul style={{ listStyleType: 'none', padding: 0 }}>
            {issuedItems
                .sort((a, b) => a.itemName.localeCompare(b.itemName)) // Sort issued items alphabetically by itemName
                .map((item, index) => (
                    <li key={index} style={listItemStyle}>
                        <span style={itemDetailStyleInline}><strong>{index + 1}. </strong> {item.itemName}</span>
                        <span style={itemDetailStyleInline}><strong> Date:</strong> {new Date(item.issue_date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</span>
                        {item.serial_number && (
                            <span style={itemDetailStyleInline}><strong> S/N#:</strong> {item.serial_number}</span>
                        )}
                        {item.quantity && item.quantity > 1 && ( // Only display if quantity is more than 1
                            <span style={itemDetailStyleInline}><strong> Quantity:</strong> {item.quantity}</span>
                        )}
                    </li>
                ))}
        </ul>
        <div style={signatureStyle}>
            <p>_____________________________</p>
            <br />
            <p>
                <strong>{abbreviateRank(IssuedUserRank)} {formattedIssuedUserName} #{IssuedUserId}</strong>
            </p>
            <p style={{ marginTop: '30px' }}>(Please sign above to acknowledge the receipt of above-listed items)</p>
        </div>
    </div>
</div>
);
});

export default PrintableComponent;