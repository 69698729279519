import React from 'react';
import { ChakraProvider, Flex, Box } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Login from './components/Login';
import Home from './components/Home';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './components/AuthContext';
import theme from './theme';
import Sidebar from './components/Sidebar';
import InventoryPage from './components/InventoryPage';
import SettingsPage from './components/SettingsPage';
import UserTable from './components/UserTable';
import UserProfile from './components/UserProfile';
import NotFound from './components/NotFound';
import GuidePage from './components/GuidePage';
import { Analytics } from '@vercel/analytics/react';

function App() {
  return (
    <AuthProvider>
      <ChakraProvider theme={theme}>
        <Helmet>
          <title>Cheverly PD Property Page</title>
          <meta name="description" content="Asset management application designed for the Cheverly Police Department in Maryland to streamline equipment tracking and inventory management." />
        </Helmet>
        <Router>
          <Flex minH="100vh">
            <Sidebar />
            <Analytics />
            <Box flex="1" minHeight="100vh" marginLeft={{ base: "0", md: "150px" }}>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                  path="/inventory"
                  element={
                    <ProtectedRoute requiredRole={['master', 'admin']}>
                      <InventoryPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <ProtectedRoute requiredRole={['master', 'admin']}>
                      <SettingsPage />
                    </ProtectedRoute>
                  }
                />
                <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
                <Route
                  path="/users"
                  element={
                    <ProtectedRoute requiredRole={['master', 'admin']}>
                      <UserTable />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/users/:id"
                  element={
                    <ProtectedRoute requiredRole={['master', 'admin']}>
                      <UserProfile />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/guide"
                  element={
                    <ProtectedRoute>
                      <GuidePage />
                    </ProtectedRoute>
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Box>
          </Flex>
        </Router>
      </ChakraProvider>
    </AuthProvider>
  );
}

export default App;