import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    heading: `'Open Sans', sans-serif`,
    body: `'Raleway', sans-serif`,
  },
  components: {

  },

  styles: {
    global: {
      'img': {
        borderRadius: '8px', // Adjust as needed // Needed to apply margin auto
      },
      'img[src$=".gif"]': { // Targeting only images that end with .gif
        display: 'block', // Make the image a block element
        marginLeft: 'auto', // Apply auto margin to the left
        marginRight: 'auto', // Apply auto margin to the right
        width: '100%', // Set the width to 50% of the container
      },
      'body': {
        color: 'gray.800',
        bg: 'gray.200', // Set the background color here
      },
      'html': {
        fontSize: { base: '14px', md: '16px' }, // Smaller font size on base, default on md and above
      },
    },
  },
});

export default theme;