import { Avatar, Box } from '@chakra-ui/react';
import { useAuth } from './AuthContext';
import { FaUser } from 'react-icons/fa'; // Importing a person icon

const UserImage = () => {
  const { user } = useAuth(); // Destructure user from useAuth

  return (
    <Box 
    mb={4}
    >
      {user ? (
        <Avatar 
          name={user.username} 
          bg="gray.200" 
          borderColor="gray.700" 
          borderWidth="2px" 
          boxShadow="md" 
          color="gray.800" // Set the text color to gray.800
        />
      ) : (
        <Avatar 
          icon={<FaUser />} 
          bg="gray.200" 
          borderColor="gray.700" 
          borderWidth="2px" 
          boxShadow="md" 
        />
      )}
    </Box>
  );
};

export default UserImage;
