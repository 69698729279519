import React, { useState, useEffect, useCallback } from 'react';
import {
  Box, Button, Heading, Table, Thead, Tbody, Tr, Th, Td, IconButton,
  useToast, TableCaption, useBreakpointValue, Container, Select, Flex, Text, AlertDialog,
  AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Tooltip,
  VStack, Divider, useColorModeValue, Input, InputGroup, InputLeftElement,
  Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon, SearchIcon } from '@chakra-ui/icons';
import axios from 'axios';

const InventoryManager = () => {
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState({
    name: '',
    description: '',
    quantity: 0,
    category_id: null
  });
  const isMobile = useBreakpointValue({ base: true, md: false });
  const renderHeader = (fullText, abbrText) => {
    return isMobile ? (
      <Tooltip label={fullText} hasArrow>
        {abbrText}
      </Tooltip>
    ) : (
      fullText
    );
  };

  const renderCategory = (categoryName) => {
    const abbrCategoryName = categoryName.length > 2 ? `${categoryName.substring(0, 2)}...` : categoryName;
    return isMobile ? (
      <Tooltip label={categoryName} hasArrow>
        {abbrCategoryName}
      </Tooltip>
    ) : (
      categoryName
    );
  };

  const buttonSize = useBreakpointValue({ base: 'sm', md: 'md' });
  const [totalPages, setTotalPages] = useState(1);
  const [editMode, setEditMode] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const toast = useToast();
  const tableSize = useBreakpointValue({ base: 'sm', md: 'sm' });
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [currentDeleteItemId, setCurrentDeleteItemId] = useState(null);
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';
  const bgColor = useColorModeValue('gray.100', 'gray.700');
  const cardBgColor = useColorModeValue('white', 'gray.800');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryResponse = await axios.get(`${apiUrl}/api/categories`);
        setCategories(categoryResponse.data.categories || []);
      } catch (error) {
        console.error("Error fetching categories:", error);
        toast({
          title: 'Error loading categories',
          description: 'There was an error fetching the categories.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchData();
  }, [toast, apiUrl]);

  const isFormValid = () => {
    return newItem.name.trim() !== '' &&
           newItem.description.trim() !== '' &&
           !isNaN(newItem.quantity) &&
           newItem.quantity >= 0;
  };

  const fetchItems = useCallback(async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/inventory-items?page=${currentPage}&limit=${itemsPerPage}&search=${encodeURIComponent(searchTerm)}&excludeDeleted=true`);
      setItems(response.data.items || []);
      setTotalPages(response.data.totalPages || 1);
    } catch (error) {
      toast({ title: 'Error fetching items', description: error.message, status: 'error', duration: 5000, isClosable: true });
    }
  }, [currentPage, itemsPerPage, searchTerm, toast, apiUrl]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const changePage = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleDeleteItemConfirm = (id) => {
    setCurrentDeleteItemId(id);
    setIsDeleteAlertOpen(true);
  };

  const performDelete = async () => {
    if (currentDeleteItemId) {
      await handleDeleteItem(currentDeleteItemId);
    }
    setIsDeleteAlertOpen(false);
  };
  
  const handleAddItem = async () => {
    if (!isFormValid() || newItem.category_id === null) {
      toast({
        title: 'Invalid input',
        description: 'Please fill all fields correctly and select a category.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
      return;
    }
  
    try {
      const response = await axios.post(`${apiUrl}/api/inventory-items`, newItem);
      if (response.status === 201) {
        const addedItem = response.data.item;
        if (addedItem) {
          setItems(prevItems => [...prevItems, addedItem]);
        } else {
          await fetchItems();
        }
        setNewItem({ name: '', description: '', quantity: 0, category_id: null });
        toast({ title: 'Item added successfully', status: 'success', duration: 5000, isClosable: true });
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        toast({ 
          title: 'Permission Denied', 
          description: 'You do not have permission to add this item.', 
          status: 'warning', 
          duration: 5000, 
          isClosable: true 
        });
      } else {
        toast({ 
          title: 'Error adding item', 
          description: error.response?.data?.message || 'An unexpected error occurred', 
          status: 'error', 
          duration: 5000, 
          isClosable: true 
        });
      }
    }
  };

  const handleEditItem = async (id) => {
    if (!isFormValid() || newItem.category_id === null) {
      toast({
        title: 'Invalid input',
        description: 'Please fill all fields correctly and select a category.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
      return;
    }
  
    try {
      await axios.put(`${apiUrl}/api/inventory-items/${id}`, newItem);
      await fetchItems();
      setEditMode(false);
      setEditItemId(null);
      setNewItem({ name: '', description: '', quantity: 0, category_id: null });
      toast({ title: 'Item updated successfully', status: 'success', duration: 5000, isClosable: true });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        toast({ 
          title: 'Permission Denied', 
          description: 'You do not have permission to edit this item.', 
          status: 'warning', 
          duration: 5000, 
          isClosable: true 
        });
      } else {
        toast({ 
          title: 'Error updating item', 
          description: error.response?.data?.message || 'An unexpected error occurred', 
          status: 'error', 
          duration: 5000, 
          isClosable: true 
        });
      }
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      const response = await axios.delete(`${apiUrl}/api/inventory-items/${id}`);
      if (response.status === 200) {
        toast({ title: 'Item deleted successfully', status: 'success', duration: 5000, isClosable: true });
      }
      await fetchItems();
    } catch (error) {
      if (error.response && error.response.status === 403) {
        toast({ 
          title: 'Permission Denied', 
          description: 'You do not have permission to delete this item.', 
          status: 'warning', 
          duration: 5000, 
          isClosable: true 
        });
      } else {
        toast({ 
          title: 'Error deleting item', 
          description: 'An unexpected error occurred', 
          status: 'error', 
          duration: 5000, 
          isClosable: true 
        });
      }
    }
  };

  return (
    <Container maxW={{ base: "90%", md: "container.lg" }} p={0} centerContent>
      <Box
        bg={bgColor}
        p={{ base: "3", md: "1" }}
        borderRadius="lg"
        boxShadow="xl"
        w="full"
        maxW={{ base: "100%", md: "80%" }}
      >
        <VStack spacing={8} width="100%">
          <Box
            p="2"
            boxShadow="md"
            borderRadius="md"
            bg={cardBgColor}
            width="100%"
          >
            <Heading as="h3" size="lg" mb="4" textAlign="center">Inventory Management</Heading>
            <Divider mb="4" />
            <InputGroup mb={4}>
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon color="gray.300" />}
              />
              <Input
                width={{ base: "100%", md: "25%" }}
                placeholder="Search items"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                focusBorderColor="teal.400"
                _hover={{
                  borderColor: "teal.300",
                }}
                _focus={{
                  boxShadow: "0 0 0 1px rgba(72, 187, 120, 0.6)",
                }}
              />
            </InputGroup>
            <Accordion allowToggle index={isAccordionExpanded ? 0 : -1}>
              <AccordionItem>
                <h2>
                  <AccordionButton onClick={() => setIsAccordionExpanded(!isAccordionExpanded)}>
                    <Box flex="1" textAlign="left">
                      Add New Item
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Box mb={4} display="flex" flexDirection={{ base: "column", md: "row" }} alignItems="center" gap={2}>
                    <Input
                      width={{ base: "100%", md: "auto" }}
                      placeholder="Item Name"
                      value={newItem.name}
                      onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
                    />
                    <Input
                      width={{ base: "100%", md: "auto" }}
                      placeholder="Item Description"
                      value={newItem.description}
                      onChange={(e) => setNewItem({ ...newItem, description: e.target.value })}
                    />
                    <Input
                      width={{ base: "100%", md: "auto" }}
                      placeholder="Quantity"
                      type="number"
                      value={newItem.quantity}
                      onChange={(e) => {
                        const parsedValue = parseInt(e.target.value, 10);
                        setNewItem({
                          ...newItem,
                          quantity: isNaN(parsedValue) ? '' : parsedValue,
                        });
                      }}
                    />
                    <Select
                      placeholder="Select category"
                      value={newItem.category_id || ''}
                      onChange={(e) => setNewItem({ ...newItem, category_id: e.target.value })}
                    >
                      {categories.map(category => (
                        <option key={category.id} value={category.id}>{category.name}</option>
                      ))}
                    </Select>
                    {editMode && (
                      <Input
                        width={{ base: "100%", md: "auto" }}
                        placeholder="SKU"
                        value={newItem.sku || ''}
                        isReadOnly
                      />
                    )}
                   <Tooltip label={editMode ? 'Update Item' : 'Add New Item'} hasArrow>
  <Button
    onClick={editMode ? () => handleEditItem(editItemId) : handleAddItem}
  >
    {editMode ? 'Update' : 'Add Item'}
  </Button>
</Tooltip>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>

          <Box
            p="4"
            boxShadow="md"
            borderRadius="md"
            bg={cardBgColor}
            width="100%"
            overflowX="auto"
          >
            <Table variant="simple" size={tableSize}>
              <TableCaption>Inventory Items</TableCaption>
              <Thead>
                <Tr>
                  <Th display={{ base: 'none', md: 'table-cell' }}>SKU</Th>
                  <Th>{renderHeader('Name', 'Nam')}</Th>
                  <Th display={{ base: 'none', md: 'table-cell' }}>Description</Th>
                  <Th isNumeric>{renderHeader('Quantity', 'Qty')}</Th>
                  <Th>{renderHeader('Category', 'Cat')}</Th>
                  <Th>{renderHeader('Actions', 'Act')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {items.map((item) => (
                  <Tr key={item.id}>
                    <Td display={{ base: 'none', md: 'table-cell' }}>{item.sku}</Td>
                    <Td>
                      <Tooltip label={item.name} placement="bottom" hasArrow>
                        {isMobile
                          ? item.name.length > 6
                            ? `${item.name.substring(0, 6)}...`
                            : item.name
                          : item.name.length > 15
                            ? `${item.name.substring(0, 15)}...`
                            : item.name}
                      </Tooltip>
          </Td>
                    <Td display={{ base: 'none', md: 'table-cell' }}>{item.description}</Td>
                    <Td isNumeric>{item.quantity}</Td>
                    <Td>{renderCategory(categories.find(c => c.id === item.category_id)?.name || 'No Category')}</Td>
                    <Td>
                    <Flex gap="0">
  <Tooltip label="Edit" hasArrow>
    <IconButton 
      aria-label="Edit" 
      icon={<EditIcon />} 
      size={buttonSize}
      onClick={() => {
        setEditMode(true);
        setEditItemId(item.id);
        setNewItem({
          name: item.name,
          description: item.description,
          quantity: item.quantity,
          category_id: item.category_id ? item.category_id.toString() : '',
          sku: item.sku
        });
        setIsAccordionExpanded(true);  // Ensure accordion is expanded on edit
      }} 
    />
  </Tooltip>
  <Tooltip label="Delete" hasArrow>
    <IconButton 
      aria-label="Delete" 
      icon={<DeleteIcon />} 
      size={buttonSize}
      onClick={() => handleDeleteItemConfirm(item.id)} 
    />
  </Tooltip>
</Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </VStack>
      </Box>
      <Flex justify="space-between" align="center" p={4} gap={4}>
  <Tooltip label="Previous Page" hasArrow>
    <Button onClick={() => changePage(currentPage - 1)} disabled={currentPage <= 1}>
      Previous
    </Button>
  </Tooltip>
  <Text>Page {currentPage} of {totalPages}</Text>
  <Tooltip label="Next Page" hasArrow>
    <Button onClick={() => changePage(currentPage + 1)} disabled={currentPage >= totalPages}>
      Next
    </Button>
  </Tooltip>
</Flex>

      <AlertDialog isOpen={isDeleteAlertOpen} onClose={() => setIsDeleteAlertOpen(false)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Delete
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={() => setIsDeleteAlertOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={performDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Container>
  );
};

export default InventoryManager;
