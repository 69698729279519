import React from 'react';
import { Box, Heading, VStack, Divider, useColorModeValue } from '@chakra-ui/react';
import CategoryCreator from './CategoryCreator';
import UserManagement from './UserManagement';

const SettingsPage = () => {
    const bgColor = useColorModeValue('gray.100', 'gray.700');
    const cardBgColor = useColorModeValue('white', 'gray.800');

    return (
        <Box
            width={{ base: "95%", sm: "80%", md: "75%", lg: "60%" }}
            p="4"
            bg={bgColor}
            mx="auto"
            my="5"
            borderRadius="lg"
            boxShadow="xl"
        >
            <VStack spacing={8}>
            <Box
                    p="4"
                    boxShadow="md"
                    borderRadius="md"
                    bg={cardBgColor}
                    width="100%"
                >
                    <Heading as="h3" size="lg" mb="4" textAlign="center">Manage Users</Heading>
                    <Divider mb="4" />
                    <UserManagement />
                </Box>
                <Box
                    p="4"
                    boxShadow="md"
                    borderRadius="md"
                    bg={cardBgColor}
                    width="100%"
                >
                    <Heading as="h3" size="lg" mb="4" textAlign="center">Category Management</Heading>
                    <Divider mb="4" />
                    <CategoryCreator />
                </Box>
            </VStack>
        </Box>
    );
};

export default SettingsPage;
