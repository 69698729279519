import React, { useState, useEffect, useMemo } from 'react';
import {
    Table, Thead, Tbody, Tr, Th, Td, Box, Container, Input,
    useToast, Skeleton, Flex, IconButton, Tooltip, useBreakpointValue, VStack, Text, InputGroup, InputLeftElement,
    Stat, StatLabel, StatNumber, StatGroup, SimpleGrid, useColorModeValue
} from '@chakra-ui/react';
import { ViewIcon, ChevronUpIcon, ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import { FaUser, FaUserTie, FaUserSecret } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from 'axios';

const UserTable = () => {
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const toast = useToast();
    const isMobile = useBreakpointValue({ base: true, md: false });
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';

    const calculateStats = (users) => {
        const officerCount = users.filter(user => user.rank && user.rank.toLowerCase() !== 'civilian').length;
        const civilianCount = users.filter(user => user.rank && user.rank.toLowerCase() === 'civilian').length;
        return { officerCount, civilianCount };
    };

    const officersBgColor = useColorModeValue("white", "gray.800");
    const civiliansBgColor = useColorModeValue("white", "gray.800");


    const abbreviateRank = (rank) => {
        const rankAbbreviations = {
            'Officer': 'Ofc.',
            'Private First Class': 'PFC.',
            'Detective': 'Det.',
            'Corporal': 'Cpl.',
            'Sergeant': 'Sgt.',
            'Captain': 'Capt.',
            'Chief': 'Chief',
            'Civilian': 'Civ.',
            'Assistant Chief': 'Asst. Chief'
        };
        return rankAbbreviations[rank] || rank;
    };

    const formatUserId = (id, rank) => {
        if (rank && rank.toLowerCase() === 'civilian') {
            const paddedId = String(id).padStart(3, '0');
            return `C${paddedId}`;
        }
        return id.toString();
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${apiUrl}/api/users`);
                setFilteredUsers(response.data.users);
            } catch (error) {
                console.error('Error fetching users:', error);
                toast({
                    title: 'Error fetching users',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchUsers();
    }, [toast, apiUrl]);

    const sortedUsers = useMemo(() => {
        let sortableUsers = [...filteredUsers].filter(user => 
            user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.role.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (user.rank && user.rank.toLowerCase().includes(searchTerm.toLowerCase()))
        );
        if (sortConfig.key !== null) {
            sortableUsers.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableUsers;
    }, [filteredUsers, sortConfig, searchTerm]);

    const stats = useMemo(() => calculateStats(sortedUsers), [sortedUsers]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const renderSortIndicator = (columnName) => {
        if (sortConfig.key === columnName) {
            return sortConfig.direction === 'ascending' ? <ChevronUpIcon /> : <ChevronDownIcon />;
        }
        return null;
    };

    const getRoleIcon = (role) => {
        switch(role.toLowerCase()) {
            case 'admin': return <FaUserTie />;
            case 'master': return <FaUserSecret />;
            default: return <FaUser />;
        }
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    if (isLoading) {
        return (
            <Flex justify="center" align="center" height="100vh">
                <Skeleton height="20px" width="80%" />
            </Flex>
        );
    }

    return (
        <Container maxW="container.xl" pt={{ base: 12, md: 16 }} px={4} pb={16} mt={8}>
            <VStack spacing={6} align="stretch">
                {/* Statistics Section */}
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                    <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={officersBgColor}>
                        <StatGroup>
                            <Stat>
                                <StatLabel fontSize="lg">Officers</StatLabel>
                                <StatLabel fontSize="sm" color="gray.500" mt={1}>
                                    <i>*This includes all sworn members</i>
                                </StatLabel>
                                <StatNumber fontSize="4xl" fontWeight="bold" color="blue.500">
                                    {stats.officerCount}
                                </StatNumber>
                            </Stat>
                        </StatGroup>
                    </Box>
                    <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={civiliansBgColor}>
                        <StatGroup>
                            <Stat>
                                <StatLabel fontSize="lg">Civilians</StatLabel>
                                <StatNumber fontSize="4xl" fontWeight="bold" color="green.500">
                                    {stats.civilianCount}
                                </StatNumber>
                            </Stat>
                        </StatGroup>
                    </Box>
                </SimpleGrid>

                <Box width="100%" maxWidth="500px" mx="auto">
                <InputGroup size="lg">
                    <InputLeftElement pointerEvents="none">
                        <SearchIcon color="gray.500" />
                    </InputLeftElement>
                    <Input
                        placeholder="Search users..."
                        value={searchTerm}
                        onChange={handleSearch}
                        bg="white"
                        borderColor="gray.300"
                        _hover={{ borderColor: "gray.400" }}
                        _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px blue.500" }}
                    />
                </InputGroup>
            </Box>
                <Box overflowX="auto" maxHeight="calc(100vh - 300px)" overflowY="auto">
                    <Table variant="simple" size={isMobile ? 'sm' : 'md'}>
                        <Thead position="sticky" top={0} bg="gray.100" zIndex={1}>
                            <Tr>
                                <Th onClick={() => requestSort('id')}>
                                    ID {renderSortIndicator('id')}
                                </Th>
                                <Th onClick={() => requestSort('name')}>
                                    Name {renderSortIndicator('name')}
                                </Th>
                                <Th onClick={() => requestSort('role')}>
                                    Role {renderSortIndicator('role')}
                                </Th>
                                <Th>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {sortedUsers.map((user, index) => (
                                <Tr key={user.id} _hover={{ bg: "gray.50" }} bg={index % 2 === 0 ? "white" : "gray.50"}>
                                    <Td>{formatUserId(user.id, user.rank)}</Td>
                                    <Td>
                                        {user.rank ? `${abbreviateRank(user.rank)} ` : ''}
                                        {user.name ? user.name.charAt(0).toUpperCase() + user.name.slice(1) : 'N/A'}
                                    </Td>
                                    <Td>
                                        <Flex align="center">
                                            {getRoleIcon(user.role)}
                                            <Text ml={2}>{user.role ? user.role.charAt(0).toUpperCase() + user.role.slice(1) : 'N/A'}</Text>
                                        </Flex>
                                    </Td>
                                    <Td>
                                        <Tooltip label="View User" hasArrow>
                                            <IconButton
                                                as={Link}
                                                to={`/users/${user.id}`}
                                                icon={<ViewIcon />}
                                                colorScheme="blue"
                                                aria-label="View User"
                                            />
                                        </Tooltip>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            </VStack>
        </Container>
    );
};

export default UserTable;