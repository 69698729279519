import React, { useState, useRef } from 'react';
import {
  Box,
  VStack,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  useToast,
  Flex,
  Spacer,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Tooltip,
  Text
} from '@chakra-ui/react';
import { FiHome, FiBox, FiSettings, FiLogIn, FiArrowLeft, FiArrowRight, FiHelpCircle, FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { FaSignOutAlt } from 'react-icons/fa';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import UserImage from './UserImage';

const sidebarBgColor = 'gray.800';
const sidebarBorderColor = 'transparent';
const iconButtonHoverBgColor = 'gray.700';
const iconButtonHoverColor = 'teal.200';

const SidebarContent = ({ onClose, isExpanded, ...rest }) => {
  const { logout, isLoggedIn, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const [isLogoutConfirmOpen, setIsLogoutConfirmOpen] = useState(false);
  const cancelRef = useRef();

  const handleLogoutClick = () => setIsLogoutConfirmOpen(true);
  const handleLogoutCancel = () => setIsLogoutConfirmOpen(false);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
      toast({
        title: 'Logged out',
        description: 'You have successfully logged out.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setIsLogoutConfirmOpen(false);
      if (onClose) onClose();
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to log out. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const NavItem = ({ to, label, icon }) => (
    <Tooltip label={label} placement="right" isDisabled={isExpanded}>
      <IconButton
        as={RouterLink}
        to={to}
        aria-label={label}
        icon={icon}
        variant="ghost"
        size="lg"
        _hover={{ bg: iconButtonHoverBgColor, color: iconButtonHoverColor }}
        bg={location.pathname === to ? 'teal.500' : 'transparent'}
        w={isExpanded ? 'full' : 'auto'}
        justifyContent={isExpanded ? 'flex-start' : 'center'}
      >
        {isExpanded && <Text ml={2}>{label}</Text>}
      </IconButton>
    </Tooltip>
  );

  return (
    <Flex
      direction="column"
      h="100vh"
      bg={sidebarBgColor}
      borderRightColor={sidebarBorderColor}
      pos="fixed"
      w={isExpanded ? '200px' : '60px'}
      transition="width 0.2s"
      {...rest}
    >
      <VStack align="stretch" p="2" spacing={2} flex="1">
        {isLoggedIn && (
          <>
            <NavItem to="/" label="Home" icon={<FiHome color="white" />} />
            {(user.role === 'admin' || user.role === 'master') && (
              <>
                <NavItem to="/inventory" label="Inventory" icon={<FiBox color="white" />} />
                <NavItem to="/settings" label="Settings" icon={<FiSettings color="white" />} />
              </>
            )}
            <NavItem to="/guide" label="Guide" icon={<FiHelpCircle color="white" />} />
          </>
        )}

        {!isLoggedIn && <NavItem to="/login" label="Login" icon={<FiLogIn color="white" />} />}

        <Box borderTop="1px" borderColor="gray.600" my={2} />

        {isLoggedIn && (
          <Tooltip label="Logout" placement="right" isDisabled={isExpanded}>
            <IconButton
              aria-label="Logout"
              icon={<FaSignOutAlt color="white" />}
              variant="ghost"
              size="lg"
              _hover={{ bg: iconButtonHoverBgColor, color: iconButtonHoverColor }}
              onClick={handleLogoutClick}
              w={isExpanded ? 'full' : 'auto'}
              justifyContent={isExpanded ? 'flex-start' : 'center'}
            >
              {isExpanded && <Text ml={2}>Logout</Text>}
            </IconButton>
          </Tooltip>
        )}

        <AlertDialog
          isOpen={isLogoutConfirmOpen}
          leastDestructiveRef={cancelRef}
          onClose={handleLogoutCancel}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Confirm Logout
              </AlertDialogHeader>
              <AlertDialogBody>Are you sure you want to log out?</AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={handleLogoutCancel}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={handleLogout} ml={3}>
                  Logout
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        <Spacer />
        <UserImage />
      </VStack>
    </Flex>
  );
};

const Sidebar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Box minH="100vh" bg="gray.200">
      <IconButton
        display={{ base: 'block', md: 'none' }}
        icon={isOpen ? <FiArrowLeft size="26" /> : <FiArrowRight size="22" />}
        onClick={isOpen ? onClose : onOpen}
        variant="outline"
        aria-label={isOpen ? 'Close Menu' : 'Open Menu'}
        position="fixed"
        bg="transparent"
        size="lg"
        _hover={{ bg: 'transparent' }}
        border={0}
        left={2}
        top={2}
        zIndex={2}
      />

      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent bg={sidebarBgColor} p={0} maxWidth={isExpanded ? '200px' : '60px'}>
            <DrawerCloseButton color="white" />
            <SidebarContent onClose={onClose} isExpanded={isExpanded} />
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>

      <Box display={{ base: 'none', md: 'block' }} position="fixed" left={0} top={0} bottom={0}>
        <Flex direction="column" h="100%">
          <IconButton
            icon={isExpanded ? <FiChevronLeft /> : <FiChevronRight />}
            onClick={() => setIsExpanded(!isExpanded)}
            aria-label={isExpanded ? 'Collapse Sidebar' : 'Expand Sidebar'}
            alignSelf="flex-end"
            variant="ghost"
            color="white"
            _hover={{ bg: iconButtonHoverBgColor }}
            size="sm"
            m={1}
          />
          <SidebarContent isExpanded={isExpanded} />
        </Flex>
      </Box>
    </Box>
  );
};

export default Sidebar;