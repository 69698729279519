import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Input, Button, useToast, HStack, Tag, TagLabel, TagCloseButton } from '@chakra-ui/react';
import axios from 'axios';
import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react';

const CategoryCreator = () => {
  const [categoryName, setCategoryName] = useState('');
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState('');
  const cancelRef = useRef();

  const confirmDeleteCategory = (categoryId, categoryName) => {
    setSelectedCategoryId(categoryId);
    setSelectedCategoryName(categoryName);
    setIsAlertOpen(true);
  };

  const fetchCategories = useCallback(async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/categories`);
      if (Array.isArray(response.data.categories)) {
        setCategories(response.data.categories);
      } else {
        console.error('Invalid response format:', response.data);
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Could not fetch categories. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast, apiUrl]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const handleAddCategory = async () => {
    if (!categoryName.trim()) {
      toast({
        title: 'Error',
        description: 'Please enter a category name.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    try {
      await axios.post(`${apiUrl}/api/categories`, { name: categoryName.trim() });
      setCategoryName('');
      toast({
        title: 'Success',
        description: 'Category added successfully!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      fetchCategories();
    } catch (error) {
      if (error.response && error.response.status === 409) {
        toast({
          title: 'Duplicate Category',
          description: 'A category with that name already exists.',
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Error',
          description: error.response?.data?.message || 'Failed to add category. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteCategory = async () => {
    setIsAlertOpen(false);
    setIsLoading(true);
    try {
      await axios.delete(`${apiUrl}/api/categories/${selectedCategoryId}`);
      toast({
        title: 'Success',
        description: 'Category deleted successfully!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      fetchCategories();
    } catch (error) {
      toast({
        title: 'Error',
        description: error.response?.data?.message || 'Failed to delete category.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box width={{ base: 'full', sm: 'auto' }} maxWidth="360px" mx="auto" display="flex" flexDirection="column" alignItems="center" gap="2">
      <Input
        placeholder="Enter category name"
        value={categoryName}
        onChange={(e) => setCategoryName(e.target.value)}
        size="md"
        width="full"
        disabled={isLoading}
      />
      <Button
        onClick={handleAddCategory}
        size="md"
        width="full"
        colorScheme="blue"
        isLoading={isLoading}
        loadingText="Adding"
        isDisabled={!categoryName.trim() || isLoading}
      >
        Add Category
      </Button>
      <HStack spacing={2} wrap="wrap">
        {categories.map((category, index) => (
          <Tag
            key={category.id}
            size="md"
            borderRadius="full"
            colorScheme={['red', 'green', 'yellow', 'purple', 'blue'][index % 5]}
            onClick={() => confirmDeleteCategory(category.id, category.name)}
            cursor="pointer"
          >
            <TagLabel>{category.name}</TagLabel>
            <TagCloseButton onClick={() => confirmDeleteCategory(category.id, category.name)} />
          </Tag>
        ))}
      </HStack>

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Category
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete the "{selectedCategoryName}" category? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsAlertOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeleteCategory} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default CategoryCreator;