import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import UserTable from './UserTable';


const Home = () => {
  return (
    <Flex
      align="center" // Align items vertically
      justify="center" // Center items horizontally
      h="100vh" // Set height to full viewport height
      p={4} // Padding around the Flex container
    >
      <Box textAlign="center" mt="50px">
        <UserTable/>
      </Box>
    </Flex>
  );
}




export default Home;
