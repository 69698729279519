import React from 'react';
import { Flex } from '@chakra-ui/react';
import InventoryManager from './InventoryManager';

const InventoryPage = () => {
  return (
    <Flex
      direction={{ base: 'column', md: 'row' }} // Stack vertically on small screens, horizontally on medium and above
      justify="center" // Adjust as needed
      align="center" // Adjust as needed
      p={{ base: 2, md: 4 }} // Less padding on smaller screens
    >
      <InventoryManager/>
    </Flex>
  );
};

export default InventoryPage;
