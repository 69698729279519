import React, { useState } from 'react';
import {
  Box,
  Flex,
  VStack,
  Link,
  Image,
  useColorModeValue,
  Heading,
  Text,
  useBreakpointValue,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button
} from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import { HamburgerIcon } from '@chakra-ui/icons';

const documentationSections = [
  {
    id: 'overview',
    title: 'Overview',
    content: `
# Cheverly PD Property Page Overview

Welcome to the Cheverly PD Property Page documentation. This system is designed to efficiently manage and track equipment for the Cheverly Police Department.

![Overview of the Cheverly PD Property Page](/images/homepage.png)

## Key Features

- **User Management**: Easily manage department personnel and their access levels.
- **Inventory Tracking**: Keep track of all department equipment and supplies.
- **Equipment Assignment**: Assign and track equipment issued to personnel.
- **Reporting Tools**: Generate reports for inventory status and equipment assignments.

This guide will walk you through each feature of the system, ensuring you can effectively use all aspects of the Cheverly PD Property Page.
    `
  },
  {
    id: 'login',
    title: 'Logging In',
    content: `
# Logging In to Cheverly PD Property Page

To access the system, you need to log in with your credentials.

![Login Page](/images/login.png)

## Login Process

1. Navigate to the login page
2. Enter your username in the 'Username' field
3. Enter your password in the 'Password' field
4. Click the "Login" button

**Note**: If you have trouble logging in, please contact your system administrator. For security reasons, do not share your login credentials with others.

## Authentication

- The system uses JSON Web Tokens (JWT) for secure authentication.
- Each login session is valid for 1 hour, after which you'll need to log in again.
- Your user role (user, admin, or master) determines your access level in the system.
    `
  },
  {
    id: 'user-management',
    title: 'User Management',
    content: `
# User Management

The User Management section allows administrators to manage user accounts.

![The User Management](/images/homepage.png)

## Key Functions

- **View all users**: Administrators can see a list of all users in the system.
- **Add new users**: Create new user accounts with specific roles and permissions.
- **Edit user information**: Update existing user details, including name, role, and rank.
- **Assign roles and permissions**: Set user roles (user, admin, master) to control access levels.

## User Roles

- **User**: Basic access to view and manage assigned equipment.
- **Admin**: Can manage inventory, assign equipment, and view reports.
- **Master**: Full access to all system features, including user management and system settings.

Proper user management ensures that each member of the department has appropriate access to the system.
    `
  },
  {
    id: 'inventory-management',
    title: 'Inventory Management',
    content: `
# Inventory Management

The Inventory Management page is where you can view, add, edit, and manage all items in the Cheverly PD inventory.

![Inventory Management Page](/images/inventorymanagement.png)

## Key Features

1. **Item List**: View all items with details like name, description, quantity, and category.
2. **Add New Item**: Easily add new items to the inventory with a unique SKU.
3. **Search and Filter**: Quickly find items using the search function or category filter.
4. **Edit and Delete**: Update item details or remove items from the inventory.

## Adding Items

To add a new item:
1. Fill in the item name, description, and quantity.
2. Select a category for the item.
3. The system automatically generates a unique SKU for each item.

## Editing Items

To edit an existing item:
1. Click the "Edit" button next to the item.
2. Update the necessary information.
3. Save your changes.

## Deleting Items

When deleting an item:
1. The item is soft-deleted (marked as deleted but not removed from the database).
2. Any issued items related to the deleted inventory item are also removed.

**Note**: Inventory management requires admin or master role access.
    `
  },
  {
    id: 'equipment-assignment',
    title: 'Equipment Assignment',
    content: `
# Equipment Assignment

This section covers how to assign equipment to department personnel.

![Equipment Assignment](/images/issueitem.png)

## Assignment Process

1. **Select a User**: Choose the user to whom you want to assign equipment.
2. **Choose Equipment**: Select the item(s) from the inventory to assign.
3. **Specify Quantity**: Enter the quantity of each item to be assigned.
4. **Add Serial Number**: If applicable, enter the serial number for the assigned item.
5. **Record Assignment**: Confirm the assignment to update the system.

## Managing Assignments

- View all items assigned to a specific user.
- Update serial numbers or quantities of assigned items.
- Remove assigned items when they are returned.

![Printout of Items Issued](/images/printout.png)

## Printouts

Generate printouts of assigned equipment for record-keeping and user acknowledgment.

Proper equipment assignment ensures accountability and efficient resource management.
    `
  },
  {
    id: 'reporting',
    title: 'Reporting',
    content: `
# Reporting Tools

The reporting section provides tools to generate various reports on inventory and equipment status.

## Available Reports

- **Current Inventory Status**: View the current quantity and status of all items.
- **Equipment Assignment History**: Track the history of equipment assignments to users.

These reports help in decision-making and maintaining an overview of the department's resources.

**Note**: Detailed reporting features are available to admin and master users.
    `
  },
  {
    id: 'settings',
    title: 'Settings',
    content: `
# System Settings

The Settings page allows administrators to configure various aspects of the system.

![System Settings](/images/systemsettings.png)

## Configurable Options

- **User Roles and Permissions**: Manage access levels for different user types.
- **Inventory Categories**: Create and manage categories for inventory items.
- **System Configuration**: Set up system-wide parameters and preferences.

Proper configuration ensures the system meets the specific needs of the Cheverly Police Department.

**Note**: Only users with the master role can access and modify system settings.
    `
  },
  {
    id: 'troubleshooting',
    title: 'Troubleshooting',
    content: `
# Troubleshooting Guide

This section provides solutions to common issues you might encounter while using the system.

## Common Issues and Solutions

- **Login Problems**: Ensure your username and password are correct. Contact an administrator if you're unable to access your account.
- **Inventory Discrepancies**: Regular audits can help identify and resolve discrepancies in inventory counts.
- **Assignment Errors**: Double-check item availability before assigning equipment.
- **User Permission Issues**: Verify that your account has the necessary permissions for the action you're trying to perform.

If you encounter a problem not covered here, please contact the system administrator or IT support.
    `
  }
];

const GuidePage = () => {
  const [selectedSection, setSelectedSection] = useState(documentationSections[0]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  const sidebarBgColor = useColorModeValue('gray.100', 'gray.700');
  const linkColor = useColorModeValue('gray.700', 'gray.200');
  const activeLinkColor = useColorModeValue('teal.600', 'teal.200');
  const activeBgColor = useColorModeValue('gray.200', 'gray.600');
  
  const isMobile = useBreakpointValue({ base: true, md: false });

  const customRenderers = {
    img: ({src, alt}) => (
      <Image src={src} alt={alt} maxWidth="100%" my={4} borderRadius="md" boxShadow="lg" />
    ),
    h1: ({children}) => <Heading as="h1" size="xl" my={4}>{children}</Heading>,
    h2: ({children}) => <Heading as="h2" size="lg" my={3}>{children}</Heading>,
    p: ({children}) => <Text fontSize="md" my={2}>{children}</Text>,
  };

  const SidebarContent = () => (
    <VStack 
      align="stretch" 
      width={isMobile ? "full" : "250px"}
      p={4} 
      bg={sidebarBgColor} 
      minHeight={isMobile ? "auto" : "100%"}
      spacing={2}
    >
      <Heading as="h3" size="md" mb={4}>Documentation</Heading>
      {documentationSections.map((section) => (
        <Link
          key={section.id}
          onClick={() => {
            setSelectedSection(section);
            if (isMobile) onClose();
          }}
          fontWeight={selectedSection.id === section.id ? "bold" : "normal"}
          color={selectedSection.id === section.id ? activeLinkColor : linkColor}
          _hover={{ color: activeLinkColor }}
          p={2}
          borderRadius="md"
          bg={selectedSection.id === section.id ? activeBgColor : 'transparent'}
        >
          {section.title}
        </Link>
      ))}
    </VStack>
  );

  return (
    <Box>
      {isMobile && (
        <Button leftIcon={<HamburgerIcon />} onClick={onOpen} m={4}>
          Open Guide Menu
        </Button>
      )}
      <Flex direction={isMobile ? "column" : "row"} maxWidth="1200px" margin="auto" minHeight="calc(100vh - 100px)">
        {!isMobile && <SidebarContent />}
        <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="full">
          <DrawerOverlay>
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>Guide Sections</DrawerHeader>
              <DrawerBody>
                <SidebarContent />
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
        <Box flex={1} bg="white" p={6} boxShadow="md" borderRadius="md" overflowY="auto">
          <ReactMarkdown components={customRenderers}>
            {selectedSection.content}
          </ReactMarkdown>
        </Box>
      </Flex>
    </Box>
  );
};

export default GuidePage;