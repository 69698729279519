import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { 
    useToast, Button, Tooltip, IconButton, useBreakpointValue, Select, 
    FormControl, FormLabel, Box, Table, Thead, Tbody, Tr, Th, Td, Input, 
    Container, Heading, AlertDialog, AlertDialogBody, AlertDialogFooter, 
    AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, VStack, HStack,
    Text, Badge, Flex, Divider, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon
} from '@chakra-ui/react';
import { useReactToPrint } from 'react-to-print';
import PrintableComponent from './PrintableComponent';
import axios from 'axios';
import { DownloadIcon, AddIcon } from '@chakra-ui/icons';
import { useAuth } from './AuthContext';
import { FiTrash2, FiSave } from 'react-icons/fi';

const UserProfile = () => {
    const [user, setUser] = useState(null);
    const { id: userId } = useParams();
    const [itemId, setItemId] = useState('');
    const [issuedItems, setIssuedItems] = useState([]);
    const [changes, setChanges] = useState({});
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [items, setItems] = useState([]);
    const toast = useToast();
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(null);
    const [actionType, setActionType] = useState(null);
    const { user: loggedInUser } = useAuth();
    const isMobile = useBreakpointValue({ base: true, md: false }); 
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';
    const saveBtnSize = useBreakpointValue({ base: 'lg', md: 'md' });

    const componentRef = useRef();

    const formatDate = (date) => {
        const options = isMobile
            ? { month: 'short', day: 'numeric', year: 'numeric' }
            : { month: 'long', day: 'numeric', year: 'numeric' };
        return new Date(date).toLocaleDateString('en-US', options);
    };

    const formatCivilianId = (id, rank) => {
        if (rank.toLowerCase() === 'civilian') {
          const paddedId = String(id).padStart(3, '0');
          return `C${paddedId}`;
        }
        return id.toString();
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const onCloseAlert = () => setIsAlertOpen(false);

    const handleConfirmAction = (issuedItemId, type) => {
        setCurrentItemId(issuedItemId);
        setActionType(type);
        setIsAlertOpen(true);
    };

    const performAction = async () => {
        if (actionType === 'remove') {
            await handleRemoveIssuedItem(currentItemId);
        }
        onCloseAlert();
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/categories`);
                setCategories(response.data.categories);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    }, [apiUrl]);

    useEffect(() => {
        const fetchItems = async () => {
            if (!selectedCategory) return;
            try {
                const response = await axios.get(`${apiUrl}/api/inventory-items/category/${selectedCategory}?excludeDeleted=true`);
                setItems(response.data.items);
            } catch (error) {
                console.error('Error fetching items:', error);
            }
        };
        fetchItems();
    }, [selectedCategory, apiUrl]);

    const fetchIssuedItems = useCallback(async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/issued-items/${userId}`);
            setIssuedItems(response.data.issuedItems);
        } catch (error) {
            console.error('Error fetching issued items:', error);
        }
    }, [userId, apiUrl]);

    useEffect(() => {
        fetchIssuedItems();
    }, [fetchIssuedItems]);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/users/${userId}`);
                setUser(response.data.user);
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };
        if (userId) {
            fetchUser();
        }
    }, [userId, apiUrl]);

    const handleIssueItem = async () => {
        if (itemId) {
            try {
                await axios.post(`${apiUrl}/api/issue-item`, {
                    userId,
                    itemId,
                    quantity: 1,
                    serialNumber: ''
                });

                toast({
                    title: 'Success',
                    description: 'Item issued successfully.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });

                setItemId('');
                setSelectedCategory('');
                fetchIssuedItems();
            } catch (error) {
                console.error('Error issuing item:', error);
                toast({
                    title: 'Error',
                    description: error.response?.data?.message || 'Failed to issue item.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    };

    const handleSerialNumberChange = (issuedItemId, value) => {
        setChanges(prev => ({
            ...prev,
            [issuedItemId]: { ...prev[issuedItemId], serialNumber: value }
        }));
    };

    const handleQuantityChange = (issuedItemId, value) => {
        setChanges(prev => ({
            ...prev,
            [issuedItemId]: {
                ...prev[issuedItemId],
                quantity: value === '' ? undefined : value
            }
        }));
    };

    const handleRemoveIssuedItem = async (issuedItemId) => {
        try {
            await axios.delete(`${apiUrl}/api/remove-issued-item/${issuedItemId}`);
            toast({
                title: 'Success',
                description: 'Issued item removed successfully.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            fetchIssuedItems();
        } catch (error) {
            console.error('Error removing issued item:', error);
            toast({
                title: 'Error',
                description: 'Failed to remove issued item.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleSaveAllChanges = async () => {
        try {
            const updatePromises = Object.entries(changes).map(([issuedItemId, change]) => {
                const updateData = {};
                if (change.serialNumber !== undefined) updateData.serialNumber = change.serialNumber;
                if (change.quantity !== undefined) updateData.quantity = change.quantity;

                return axios.put(`${apiUrl}/api/update-issued-item/${issuedItemId}`, updateData);
            });

            await Promise.all(updatePromises);

            toast({
                title: 'Success',
                description: 'All changes saved successfully.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

            fetchIssuedItems();
            setChanges({});
        } catch (error) {
            console.error('Error saving changes:', error);
            toast({
                title: 'Error',
                description: 'Failed to save changes.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const renderMobileTable = (item) => (
        <Accordion allowToggle key={item.id}>
            <AccordionItem>
                <h2>
                    <AccordionButton>
                        <Box flex="1" textAlign="left">
                            {item.itemName}
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    <Text fontSize="sm">Issued: {formatDate(item.issue_date)}</Text>
                    <Input
                        size="sm"
                        placeholder="Serial number"
                        value={changes[item.id]?.serialNumber ?? item.serial_number ?? ''}
                        onChange={(e) => handleSerialNumberChange(item.id, e.target.value)}
                        mt={2}
                    />
                    <Input
                        size="sm"
                        placeholder="Quantity"
                        type="number"
                        value={changes[item.id]?.quantity ?? item.quantity ?? '0'}
                        onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, '');
                            handleQuantityChange(item.id, value);
                        }}
                        min="0"
                        mt={2}
                    />
                    <IconButton
                        aria-label="Remove"
                        icon={<FiTrash2 />}
                        size="sm"
                        colorScheme="red"
                        onClick={() => handleConfirmAction(item.id, 'remove')}
                        mt={2}
                    />
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );

    if (!user) {
        return <div>Loading...</div>;
    }

    return (
        <Container maxW="container.xl" p={4}>
            <VStack spacing={6} align="stretch">
                <Heading size="lg" textAlign="center">User Profile</Heading>

                <Box bg="white" p={4} borderRadius="lg" boxShadow="md">
                    <Flex justify="space-between" align="center" mb={4} direction={isMobile ? "column" : "row"}>
                        <Text fontSize="xl" fontWeight="bold">
                            {user.rank} {user.name}
                        </Text>
                        <Badge colorScheme="blue" fontSize="md">
                            {formatCivilianId(user.id, user.rank)}
                        </Badge>
                    </Flex>

                    <Divider my={4} />

                    <Accordion allowToggle>
                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left">
                                        Issue New Item
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <VStack spacing={4}>
                                    <FormControl>
                                        <FormLabel>Category</FormLabel>
                                        <Select placeholder="Select category" onChange={(e) => setSelectedCategory(e.target.value)}>
                                            {categories.map((category) => (
                                                <option key={category.id} value={category.id}>{category.name}</option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {selectedCategory && (
                                        <FormControl>
                                            <FormLabel>Item</FormLabel>
                                            <Select placeholder="Select item" onChange={(e) => setItemId(e.target.value)}>
                                                {items.map((item) => (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                    <Button
                                        leftIcon={<AddIcon />}
                                        colorScheme="teal"
                                        onClick={handleIssueItem}
                                        isFullWidth
                                    >
                                        Issue Item
                                    </Button>
                                </VStack>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>

                    <Box mt={6}>
                        <Heading size="md" mb={4}>Issued Items</Heading>
                        {isMobile ? (
                            <VStack spacing={2} align="stretch">
                                {issuedItems
                                    .concat(Object.entries(changes)
                                        .filter(([, change]) => change.isNewItem)
                                        .map(([itemId, change]) => ({
                                            id: itemId,
                                            itemName: items.find(item => item.id === itemId)?.name || 'New Item',
                                            issue_date: new Date(),
                                            ...change
                                        })))
                                    .sort((a, b) => a.itemName.localeCompare(b.itemName))
                                    .map(renderMobileTable)}
                            </VStack>
                        ) : (
                            <Box overflowX="auto">
                                <Table variant="simple" size="sm">
                                    <Thead>
                                        <Tr>
                                            <Th>Item Name</Th>
                                            <Th>Issued Date</Th>
                                            <Th>Serial Number</Th>
                                            <Th>Quantity</Th>
                                            <Th>Actions</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {issuedItems
                                            .concat(Object.entries(changes)
                                                .filter(([, change]) => change.isNewItem)
                                                .map(([itemId, change]) => ({
                                                    id: itemId,
                                                    itemName: items.find(item => item.id === itemId)?.name || 'New Item',
                                                    issue_date: new Date(),
                                                    ...change
                                                })))
                                            .sort((a, b) => a.itemName.localeCompare(b.itemName))
                                            .map((item) => (
                                                <Tr key={item.id}>
                                                    <Td>
                                                        <Tooltip label={item.itemName} hasArrow>
                                                            <Text isTruncated maxW="150px">{item.itemName}</Text>
                                                        </Tooltip>
                                                    </Td>
                                                    <Td>{formatDate(item.issue_date)}</Td>
                                                    <Td>
                                                        <Input
                                                            size="sm"
                                                            placeholder="Serial number"
                                                            value={changes[item.id]?.serialNumber ?? item.serial_number ?? ''}
                                                            onChange={(e) => handleSerialNumberChange(item.id, e.target.value)}
                                                        />
                                                    </Td>
                                                    <Td>
                                                        <Input
                                                            size="sm"
                                                            placeholder="Quantity"
                                                            type="number"
                                                            value={changes[item.id]?.quantity ?? item.quantity ?? '0'}
                                                            onChange={(e) => {
                                                                const value = e.target.value.replace(/[^0-9]/g, '');
                                                                handleQuantityChange(item.id, value);
                                                            }}
                                                            min="0"
                                                        />
                                                    </Td>
                                                    <Td>
                                                        <IconButton
                                                            aria-label="Remove"
                                                            icon={<FiTrash2 />}
                                                            size="sm"
                                                            colorScheme="red"
                                                            onClick={() => handleConfirmAction(item.id, 'remove')}
                                                        />
                                                    </Td>
                                                </Tr>
                                            ))}
                                    </Tbody>
                                </Table>
                            </Box>
                        )}
                    </Box>
                </Box>

                <HStack spacing={4} justify="center">
                    <Button
                        onClick={handleSaveAllChanges}
                        colorScheme="blue"
                        isDisabled={Object.keys(changes).length === 0}
                        leftIcon={<FiSave />}
                        size={saveBtnSize}
                        position={isMobile ? "fixed" : "static"}
                        bottom={isMobile ? 4 : "auto"}
                        right={isMobile ? 4 : "auto"}
                        zIndex={2}
                        width={isMobile ? "calc(100% - 32px)" : "auto"}  // Full width on mobile minus margins
                        fontSize={isMobile ? "lg" : "md"}
                        py={isMobile ? 6 : 4}  // Increase padding for mobile
                    >
                        Save Changes
                    </Button>
                    {!isMobile && (
                        <Button
                            onClick={handlePrint}
                            colorScheme="teal"
                            leftIcon={<DownloadIcon />}
                        >
                            Print Issued Items
                        </Button>
                    )}
                </HStack>
            </VStack>

            <div style={{ display: "none" }}>
                <PrintableComponent 
                    ref={componentRef} 
                    issuedItems={issuedItems} 
                    IssuedUserName={user.name} 
                    IssuedUserId={formatCivilianId(user.id, user.rank)}
                    IssuedUserRank={user.rank}
                    LoggedInUserName={loggedInUser.name} 
                    LoggedInUserId={formatCivilianId(loggedInUser.id, loggedInUser.rank)}
                    LoggedInUserRank={loggedInUser.rank}
                />
            </div>
            <AlertDialog isOpen={isAlertOpen} onClose={onCloseAlert}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Confirm Action
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Are you sure you want to {actionType} this item?
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button onClick={onCloseAlert}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={performAction} ml={3}>
                                Yes
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Container>
    );
};

export default UserProfile;
