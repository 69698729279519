import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Box, Text, Button, Center } from '@chakra-ui/react';
import { useAuth } from './AuthContext';

const AccessDenied = () => {
  const navigate = useNavigate();

  return (
    <Center h="100vh">
      <Box textAlign="center" py={10} px={6}>
        <Text fontSize="3xl" fontWeight="bold" mb={3}>
          Access Denied
        </Text>
        <Text fontSize="lg" mb={6}>
          Sorry, you don't have permission to view this page.
        </Text>
        <Button colorScheme="blue" onClick={() => navigate('/')}>
          Return Home
        </Button>
      </Box>
    </Center>
  );
};

const ProtectedRoute = ({ children, requiredRole }) => {
  const { user, loading, isLoggedIn } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  if (requiredRole && !requiredRole.includes(user.role)) {
    return <AccessDenied />;
  }

  return children;
};

export default ProtectedRoute;
