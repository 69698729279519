import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  useToast,
  Flex,
  Heading,
  Spacer,
  useBreakpointValue
} from '@chakra-ui/react';
import axios from 'axios';
import { useAuth } from './AuthContext'; // Import your auth context

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null); // Initialize selectedUser as null
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';
  const { user, refreshToken } = useAuth();

  const isMaster = user && user.role === 'master';

  const fetchUsers = useCallback(() => {
    setIsLoading(true);
    axios.get(`${apiUrl}/api/users`)
      .then(response => {
        setUsers(response.data.users);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
        toast({ title: 'Error fetching users', status: 'error' });
      })
      .finally(() => setIsLoading(false));
  }, [toast, apiUrl]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      if (selectedUser) {
        await axios.put(`${apiUrl}/api/users/${selectedUser.id}`, {
          username: selectedUser.username,
          password: selectedUser.password,
          role: selectedUser.role,
          name: selectedUser.name,
          rank: selectedUser.rank
        });

        toast({ title: 'User updated successfully', status: 'success' });
        fetchUsers();

        // Update user in global context if the logged-in user's role was updated
        if (selectedUser.id === user.id) {
          await refreshToken(); // Refresh token if the current user's role was updated
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        toast({
          title: 'Permission Denied',
          description: 'You do not have permission to perform this action.',
          status: 'warning',
          duration: 5000,
          isClosable: true
        });
      } else {
        toast({
          title: 'Error updating user',
          description: 'An unexpected error occurred.',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleUserChange = async (userId) => {
    const numericUserId = parseInt(userId, 10);
    setIsLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/api/users/${numericUserId}`);
      setSelectedUser(response.data.user);
    } catch (error) {
      console.error('Error fetching user details:', error);
      toast({ title: 'Error fetching user details', status: 'error' });
    }
    setIsLoading(false);
  };

  const isMobileView = useBreakpointValue({ base: true, sm: false });

  return (
    <Box p={4}>
      <Flex alignItems="center" mb={4}>
        <Heading size={isMobileView ? 'md' : 'lg'}>User Management</Heading>
        <Spacer />
        <Button
          colorScheme="teal"
          type="submit"
          isLoading={isLoading}
          disabled={!isMaster || !selectedUser}
          _disabled={{ opacity: 0.6, cursor: 'not-allowed' }}
          onClick={handleSubmit}
        >
          Update
        </Button>
      </Flex>
      <form onSubmit={handleSubmit}>
        <FormControl id="user" isRequired mb={4}>
          <FormLabel>User</FormLabel>
          <Select placeholder="Select user" value={selectedUser?.id || ''} onChange={(e) => handleUserChange(e.target.value)}>
            {users.map(user => (
              <option key={user.id} value={user.id}>
                {user.name ? user.name : (user.username ? user.username : `User ID: ${user.id}`)}
              </option>
            ))}
          </Select>
        </FormControl>

        {selectedUser?.id && (
          <>
            <FormControl id="username" isRequired mb={4}>
              <FormLabel>Username</FormLabel>
              <Input value={selectedUser.username || ''} onChange={(e) => setSelectedUser({ ...selectedUser, username: e.target.value })} />
            </FormControl>

            <FormControl id="name" mb={4}>
              <FormLabel>Name</FormLabel>
              <Input value={selectedUser.name || ''} onChange={(e) => setSelectedUser({ ...selectedUser, name: e.target.value })} />
            </FormControl>

            <FormControl id="password" mb={4}>
              <FormLabel>Password</FormLabel>
              <Input type="password" value={selectedUser.password || ''} onChange={(e) => setSelectedUser({ ...selectedUser, password: e.target.value })} />
            </FormControl>

            <Flex mb={4}>
              <FormControl id="role" isRequired mr={4}>
                <FormLabel>Role</FormLabel>
                <Select value={selectedUser.role || 'user'} onChange={(e) => setSelectedUser({ ...selectedUser, role: e.target.value })}>
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                  <option value="master">Master</option>
                </Select>
              </FormControl>
              <FormControl id="rank">
                <FormLabel>Rank</FormLabel>
                <Select value={selectedUser.rank || 'user'} onChange={(e) => setSelectedUser({ ...selectedUser, rank: e.target.value })}>
                  <option value="Officer">Officer</option>
                  <option value="Detective">Detective</option>
                  <option value="Private First Class">Private First Class</option>
                  <option value="Corporal">Corporal</option>
                  <option value="Sergeant">Sergeant</option>
                  <option value="Captain">Captain</option>
                  <option value="Chief">Chief</option>
                  <option value="Asst. Chief">Asst. Chief</option>
                  <option value="Civilian">Civilian</option>
                </Select>
              </FormControl>
            </Flex>
          </>
        )}
      </form>
    </Box>
  );
};

export default UserManagement;