import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { useAuth } from './AuthContext'; // Make sure this path is correct
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  useColorModeValue,
  Heading,
  Box,
  Image,
  Flex,
  Text,
  Stack,
  Icon,
} from '@chakra-ui/react';
import { FaLock, FaUserAlt } from 'react-icons/fa';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { login } = useAuth();
  const { isLoggedIn } = useAuth(); // Destructure isLoggedIn from useAuth
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/'); // Redirect to home if already logged in
    }
  }, [isLoggedIn, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!username || !password) {
      toast({
        title: 'Error',
        description: 'Please fill in all fields.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }
    try {
      const { data } = await axios.post(`${apiUrl}/login`, { username, password });
      await login(data.token);
      toast({
        title: 'Login Successful',
        description: 'You have successfully logged in.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      navigate('/');
    } catch (error) {
      toast({
        title: 'Login Failed',
        description: error.response?.data?.message || 'Failed to login. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex
      minH="100vh"
      align="center"
      justify="center"
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Box
        maxW={{ base: '90%', sm: 'md' }}
        w="full"
        p={{ base: 6, sm: 8 }}
        borderWidth={1}
        borderRadius={8}
        boxShadow="lg"
        bg={useColorModeValue('white', 'gray.700')}
      >
        <VStack spacing={6}>
          <Box w="full">
            <Image
              src="/images/logo.png"
              alt="Logo"
              w={{ base: '150px', md: '200px' }}
              mx="auto"
            />
          </Box>
          <Heading as="h2" size={{ base: 'md', sm: 'lg' }} textAlign="center">
            Login
          </Heading>
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Stack spacing={4}>
              <FormControl id="username" isRequired>
                <FormLabel>
                  <Flex align="center">
                    <Icon as={FaUserAlt} />
                    <Text ml={2}>Username</Text>
                  </Flex>
                </FormLabel>
                <Input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Enter your username"
                />
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel>
                  <Flex align="center">
                    <Icon as={FaLock} />
                    <Text ml={2}>Password</Text>
                  </Flex>
                </FormLabel>
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                />
              </FormControl>
              <Button
                mt={4}
                colorScheme="teal"
                type="submit"
                isLoading={isLoading}
                size="lg"
                width="full"
              >
                Login
              </Button>
            </Stack>
          </form>
        </VStack>
      </Box>
    </Flex>
  );
};

export default Login;
