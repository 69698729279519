// NotFound.js

import React from 'react';
import { Box, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Box textAlign="center" p={10}>
      <Text fontSize="xl" mb={3}>Page Not Found</Text>
      <Text mb={6}>Sorry, the page you are looking for does not exist.</Text>
      <Button colorScheme="blue" onClick={() => navigate('/')}>Return Home</Button>
    </Box>
  );
};

export default NotFound;
